import fbSvg from '@/images/facebook.svg';
import xSvg from '@/images/x-icon.svg';
import instagramSvg from '@/images/instagram.svg';
import snapchatSvg from '@/images/snapchat.svg';
import youtubeSvg from '@/images/youtube.svg';
import pinterestSvg from '@/images/pinterest.svg';
import whatsappSvg from '@/images/whatsapp.svg';

export default [
	{
		image: fbSvg,
		link: 'FACEBOOK',
		name: 'fb'
	},
	{
		image: instagramSvg,
		link: 'INSTAGRAM',
		name: 'instagram'
	},
	{
		image: pinterestSvg,
		link: 'https://www.pinterest.com/e_news_/',
		name: 'pinterest',
		targetEditions: {
			us: true,
			au: true,
			uk: true,
			de: true,
			fr: true,
			ap: true
		}
	},
	{
		image: xSvg,
		link: 'X',
		name: 'x'
	},
	{
		image: snapchatSvg,
		link: 'SNAPCHAT',
		name: 'snapchat',
		targetEditions: {
			us: true,
			uk: true,
			au: true,
			ap: true,
			de: true,
			fr: true
		}
	},
	{
		image: youtubeSvg,
		link: 'YOUTUBE',
		name: 'youtube',
		targetEditions: {
			us: true,
			ca: true,
			uk: true,
			au: true,
			ap: true,
			mx: true,
			ar: true,
			ve: true,
			co: true,
			br: true
		}
	},
	{
		image: whatsappSvg,
		link: 'WHATSAPP',
		name: 'whatsapp'
	}
];

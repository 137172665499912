/**
 * A list of url param level flags with their DEFAULT values (is enabled by default)
 */
export const flags = {
	CUSTOM_MPS_ENABLED: false,
	THIRD_PARTY_ENABLED: true,
	ADOBE_LAUNCH_ENABLED: true,
	MPS_ENABLED: true,
	TABOOLA_ENABLED: true,
	SERVICE_WORKER_ENABLED: false,
	META_CONTENT_ENABLED: false,
	THEMED_PAGE: false,
	TABOOLA_AMP_OVERRIDE_ENABLED: false,
	TCF_STUB: null,
	NEW_INTEGRITY_PARAM: false,
	TABOOLA_STORIES_ENABLED_PARAM: false,
	CONTEXTUAL_WIDGET_PARAM: false,
	NIELSEN_DCR: true,
	IDENTITY_ENABLED_PARAM: false,
	MPS_ADS_PARAM: false,
	CSAI_ADS_PARAM: false,
	GAMMA_PLAYER_ENABLED: false,
	GPP_ENABLED: true,
	GAMMA_LIVE: true,
	WELLS_FARGO_PARAM: false,
	MOBILE_SUB_NAV_PARAM: false,
	CHECKOUT_TRACKING_PARAM: true,
	SPOT_ADS_ENABLED: true,
	TABOOLA_TRENDING_STORIES_PARAM: true,
	TABOOLA_GALLERY_CONTENT_RECS_PARAM: false,
	GAME_WIDGET: false
};

/**
 * @function getUrlParamBool
 * @description Will get the truthy or falsy value of a param flag and will return null if no flag or query is present
 * @param {String} name - The flag name
 * @return {Boolean|Null}
 */
const getUrlParamBool = (name) => {
	if (typeof window === 'undefined') return;
	const regex = new RegExp(`${name}=[a-zA-Z]+`);
	const isInURL = window.location.search.match(regex);
	if (!isInURL) return null;
	const value = isInURL[0].split('=')[1];
	return value === 'true';
};

/**
 * @function ssrGetUrlParamBool
 * @description (Server-side) Will get the truthy or falsy value of a param flag and will return null if no flag or query is present
 * @param {String} [name] - The query params that come from the server ex. req.query OPTIONAL
 * @param {Object} params - The query params that come from the server ex. req.query
 * @return {Boolean}
 */
const ssrGetUrlParamBool = (name, params) => {
	return typeof params[name] !== 'undefined' ? params[name] === 'true' : null;
};
/**
 * @function getFlagParamValues
 * @description formats the flags and their truthy or falsy value
 * @param {Object} [ssrParams] - The query params that come from the server ex. req.query OPTIONAL
 * @return {Object} The formatted toggles
 */
export const getFlagParamValues = (ssrParams) => {
	const params = {};
	Object.entries(flags).forEach(([name, isEnabledByDefault]) => {
		const isInURL = ssrParams ? ssrGetUrlParamBool(name, ssrParams) : getUrlParamBool(name);
		const enabled = typeof isInURL === 'boolean' ? isInURL : isEnabledByDefault;
		params[name] = enabled;
	});
	return params;
};
